const animations = {
  '塑膠飲料杯': {
    '吸管': {
      0: { rotate: 0, transformOrigin: '50% 31.3%' },
      100: { rotate: -5 },
    },
  }
}

export default animations
